body{
    height: auto;
}
.site-content{
    padding: 70px 0px 0px 0px;
}
footer{
    position: relative;
}
.preDiv {
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    padding: 20px;
    font-size: 13px;
    border-radius: 5px;
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
    overflow: auto;
}
.homepage-hero {
    background-image: url(assets/images/api-hero.jpg) !important;
    background-size: cover;
    height: 450px;
}
.homeCenterDiv{
    max-width: 775px;
    margin: 0 auto;
}
.homepage-api-icon {
    display: block;
    height: 90px;
    background-size: 90px 90px !important;
    color: #444444;
    top: 155px;
    z-index: 1;
    left: 10px;
    cursor: pointer;
}
.homepage-api-count{
    background-color: #39006B;
    padding: 50px 0px;
}
.circular-badge{
    background-color: #8017E1;
    text-align: center;
    width: 180px;
    height: 180px;
    margin: 0 auto;
    border-radius: 90px;
    padding: 35px 0px 0px 0px;
}
.circular-badge h1, .circular-badge h3{
    color: #FFFFFF;
    margin: 0px;
}
.homepage-api-div {
    padding: 50px 0px;
    background-color: #F2F1F3;
}
.homepage-api-div-container{
    max-width: 1220px;
    margin: 0 auto;
}
.api-icon-text {
    cursor: pointer;
    text-align: center;
}
.your-combination-api-icon {
    fill: #8017E1;
    text-align: center;
}
.your-combination-api-div {
    background-color: #FFFFFF;
    color: #00005C;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 32px;
    margin-bottom: 32px;
}
.homepage-reduce-checkout {
    background-color: #39006B;
}
.homepage-reduce-checkout-container{
    max-width: 722px;
    margin: 0 auto;
}
.homepage-explore-uses-container{
    max-width: 410px;
    margin: 0 auto;
}
.homepage-reduce-checkout-p {
    font-size: 20px;
    line-height: 1;
    text-align: center;
}
.homepage-docs{
    max-width: 962px;
    margin: 40px auto;
}
.floatLeft{
	float: left;
}
.floatRight{
	float: right;
}
.requiredFieldNote{
	float: right;
	margin: 40px 0px 20px 0px;
}
.clearFloat{
	clear: both;
}
.fullview{
    min-height: calc(100vh - 140px);
}
.inner-addon {
    position: relative;
}
.inner-addon .cardicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}
.inner-addon .cardiconamex {
    right: 198px;
    opacity:.4;
}
.inner-addon .cardicondinner {
    right: 165px;
    opacity:.4;
}
.inner-addon .cardicondiscover {
    right: 132px;
    opacity:.4;
}
.inner-addon .cardiconmaestro {
    right: 99px;
    opacity:.4;
}
.inner-addon .cardiconmaster {
    right: 66px;
    opacity:.4;
}
.inner-addon .cardiconvisa {
    right: 33px;
    opacity:.4;
}
.inner-addon .cardiconjcb {
    right: 0px;
    opacity:.4;
}
.right-addon input {
    padding-right: 99px;
}
.card-type-match {
    opacity: 1 !important;
}
.pricing-plan-card-featured .plan-price, .pricing-plan-card .plan-title, .pricing-plan-card .plan-price{
    color: #111111;
}
.addresscount{
    background-color: #39006B;
    color: #FFFFFF;
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 15px;
}
.modal.show .modal-dialog.modal-dialog-center{
    transform: translate(0%, 50%);
    margin: 0 auto;
}
.verified-true{
    background-color: #41986A;
    color: #FFFFFF;
    padding: 5px 10px;
}
.verified-blue{
    background-color: #4B8BFF;
    color: #FFFFFF;
    padding: 5px 10px;
}
.verified-false{
    background-color: #C9302C;
    color: #FFFFFF;
    padding: 5px 10px;
}
.span_desc{
    font-size: 10px;
    float: left;
    width: 65%;
    padding-top: 4px;
}
.recurly-element, .recurly-hosted-field {
    height: calc(1.5em + 1rem + 2px);
    margin-top: 0px;
}
.mipro-plan-cards{
    width: 600px;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
}
.btn-card-link{
    color: #8017E1;
}
.text-red{
    color: #FF0000;
    font-size: 12px;
}
.privacy-policy-icon{
    padding-left: 3rem;
    position: relative;
}
.privacy-policy-icon::before{
    content: "";
    background-image: url(assets/images/privacy-choices.svg);
    background-repeat: no-repeat;
    background-size: 2.625rem auto;
    position: absolute;
    left: 0;
    top: .0625rem;
    width: 2.625rem;
    height: 1.25rem;
}